/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { reset as giftReset } from '@actions/gift';
import { reset } from '@actions/session';
import { Button } from '@components/shared/button';
import Burger from '@components/shared/hamburger';
import { useAuth } from '@context/auth';
import { useGiftDispatch } from '@context/gift';
import { useSessionDispatch } from '@context/session';
import { useAsync } from '@hooks/use-async';
import { MenuItem } from '@models/common/main-menu';
import useStaticPageSegment from '@services/analytics/use-analytics';

import { menuItemsNonUser, menuItemsUser } from './main-menu-items';
import {
  StyledMainMenu,
  StyledMainMenuItem,
  UserDetails,
} from './main-menu.styled';

const MainMenu: React.FunctionComponent = () => {
  const { user, logout } = useAuth();
  const { isLoading: isLogoutLoading, run } = useAsync();
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const router = useRouter();
  const { trackStaticPageEvents } = useStaticPageSegment();
  const sessionDispatch = useSessionDispatch();
  const giftDispatch = useGiftDispatch();

  const toggle = () => {
    setMenuOpen(state => !state);
  };

  const clearState = () => {
    sessionDispatch(reset(undefined));
    giftDispatch(giftReset(undefined));
  };

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    run(logout())
      .then(clearState)
      .then(toggle)
      .then(() => {
        router.replace('/');
      });
  };

  const fireMenuClickEvent = (title: string | undefined) => () => {
    if (title) {
      trackStaticPageEvents(title, {}, 'track');
    }
    if (title === 'Menu - Help') {
      trackStaticPageEvents('Help Center');
    }
  };

  const renderMenuItem = (item: MenuItem, index: number) => (
    <StyledMainMenuItem key={index} data-testid={`menu-item-${item.label}`}>
      {(() => {
        const anchorProps = {
          role: 'button',
          tabIndex: 0,
          onKeyPress: fireMenuClickEvent(item.segmentTitle),
          onClick: fireMenuClickEvent(item.segmentTitle),
        };

        if (item.absolute) {
          return (
            <a
              {...anchorProps}
              href={item.href}
              target="_blank"
              rel="noreferrer"
            >
              {item.label}
            </a>
          );
        }

        return (
          <Link href={item.href}>
            <a {...anchorProps}>{item.label}</a>
          </Link>
        );
      })()}
    </StyledMainMenuItem>
  );

  const renderUserDetails = () => {
    if (isMenuOpen && user) {
      return <UserDetails>Hi, {user?.firstName}!</UserDetails>;
    }
    return null;
  };

  const renderMenuItems = () => {
    const menuItems = user ? menuItemsUser : menuItemsNonUser;

    return (
      <>
        {menuItems.map(renderMenuItem)}
        {user ? (
          <StyledMainMenuItem>
            <Button
              type="button"
              onClick={handleLogout}
              disabled={isLogoutLoading}
            >
              Logout
            </Button>
          </StyledMainMenuItem>
        ) : null}
      </>
    );
  };

  return (
    <>
      {renderUserDetails()}
      <Burger isOpen={isMenuOpen} toggle={toggle} />
      <StyledMainMenu isOpen={isMenuOpen}>
        <div css={{ maxHeight: '100%', overflowX: 'auto', paddingBottom: 20 }}>
          {renderMenuItems()}
        </div>
      </StyledMainMenu>
    </>
  );
};

export default MainMenu;
