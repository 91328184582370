import styled from '@emotion/styled';

import { MainMenuProps } from '@models/common/main-menu';
import * as colors from '@styles/colors';
import { maxWidth } from '@styles/utils';

export const StyledMainMenu = styled.nav<MainMenuProps>`
  position: fixed;
  padding: 70px 0 0 0;
  left: 0;
  z-index: 9;
  transition: top 0.4s ease-in-out;
  height: 100%;
  width: 100%;
  z-index: 12;
  background-color: ${colors.primary};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  overflow: auto;
`;

export const StyledMainMenuItem = styled.div`
  max-width: ${maxWidth};
  width: 100%;
  margin: 0 auto;
  text-align: right;
  padding-right: 15px;

  a,
  button {
    padding: 25px 5px 12px;
    width: 100%;
    max-width: 350px;
    border: 0px;
    border-bottom: 1px #ffffff80 solid;
    border-radius: 0px;
    text-align: right;
    text-decoration: none;
    transition: color 0.3s linear;
    display: inline-block;
    margin: 0 0 auto;
    color: white;
    font-size: 32px;
    font-weight: 500;
    transition: all 300ms ease-out;
    background-color: rgba(255, 255, 255, 0);
    text-transform: none;
    font-family: 'Raleway';
    max-height: revert;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
      color: ${colors.primary};
    }
  }
  a.active {
    box-shadow: 0 3px 0 white;
    font-weight: bold;
  }

  @media (max-width: 991px) {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;

    a,
    button {
      text-align: left;
      font-size: 24px;
      padding: 18px 5px 12px;
    }
  }
`;

export const UserDetails = styled.div`
  color: white;
  position: absolute;
  white-space: nowrap;
  vertical-align: middle;
  top: 50%;
  z-index: 13;
  transform: translateY(-50%);
  right: 60px;
  text-transform: uppercase;

  @media (max-width: 991px) {
    right: 20px;
  }
`;
