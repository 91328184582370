import { MenuItem } from '@models/common/main-menu';

const menuItemsNonUser: MenuItem[] = [
  {
    label: 'Log In',
    href: '/login',
  },
  {
    label: 'Sign Up',
    href: '/register',
  },
  {
    label: 'Book a Session',
    href: '/book-session/locations',
    segmentTitle: 'Menu - Book a Session',
  },
  {
    label: 'Meet our Therapists',
    href: '/therapists',
    segmentTitle: 'Menu - Meet our Therapists',
  },
  {
    label: 'Locations',
    href: '/locations',
    segmentTitle: 'Menu - Locations',
  },
  {
    label: 'Pricing',
    href: '/pricing',
    segmentTitle: 'Menu - Pricing',
  },
  {
    label: 'Give a Gift',
    href: '/gift-cards',
  },
  {
    label: 'Help',
    href: '/help',
    absolute: true,
    segmentTitle: 'Menu - Help',
  },
];

const menuItemsUser: MenuItem[] = [
  {
    label: 'Book a Session',
    href: '/book-session/locations',
    segmentTitle: 'Menu - Book a Session',
  },
  {
    label: 'My Sessions',
    href: '/my-sessions',
  },
  {
    label: 'My Account',
    href: '/my-account',
  },
  {
    label: 'My Okay Kit',
    href: '/okay-kit',
  },
  {
    label: 'Give a Gift',
    href: '/gift-cards',
  },
  {
    label: 'Invite Friends',
    href: '/refer',
  },
  {
    label: 'Help',
    href: '/help',
    absolute: true,
    segmentTitle: 'Menu - Help',
  },
];

const menuItemsMember: MenuItem[] = [
  {
    label: 'Book a Session',
    href: '/book-session/locations',
    segmentTitle: 'Menu - Book a Session',
  },
  {
    label: 'My Sessions',
    href: '/my-sessions',
  },
  {
    label: 'My Account',
    href: '/my-account',
  },
  {
    label: 'My Okay Kit',
    href: '/okay-kit',
  },
  {
    label: 'Give a Gift',
    href: '/gift-cards',
  },
  {
    label: 'Invite Friends',
    href: '/refer',
  },
  {
    label: 'Help',
    href: '/help',
    absolute: true,
    segmentTitle: 'Menu - Help',
  },
];

export { menuItemsNonUser, menuItemsUser, menuItemsMember };
