import React from 'react';
import styled from '@emotion/styled';
import VisuallyHidden from '@reach/visually-hidden';

import { BurgerProps, StyledBurgerProps } from '@models/common/burger';

export const StyledBurger = styled.button<StyledBurgerProps>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  width: 32px;
  height: 32px;
  z-index: 13;
  &:focus {
    outline: none;
  }
  div {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    margin: 5px 0;
    display: block;
    background-color: 'white';
    transition: all 0.3s ease-in-out;
    background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'white')};
    :nth-of-type(1),
    :nth-of-type(3) {
      transform: ${({ isOpen }) => (isOpen ? 'scaleX(0)' : 'scaleX(1)')};
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    }
    :nth-of-type(2) {
      transition: all 0.3s ease-in-out;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        left: 0;
        margin-top: -2px;
        background: white;
        transition: all 0.3s ease-in-out;
      }
      &:before {
        transform: ${({ isOpen }) =>
          isOpen ? 'rotate(-45deg)' : 'rotate(0deg)'};
      }
      &:after {
        transform: ${({ isOpen }) =>
          isOpen ? 'rotate(45deg)' : 'rotate(0deg)'};
      }
    }
  }

  @media (max-width: 991px) {
    font-size: 24px;
    text-align: left;
    position: absolute;
    left: 20px;
    top: 20px;
    width: 20px;

    div {
      height: 2px;
      border-radius: 4px;
      margin: 4px 0;
      :nth-of-type(2) {
        height: 2px;
        &:before,
        &:after {
          height: 2px;
          margin-top: -1px;
        }
      }
    }
  }
`;

const Burger: React.FunctionComponent<BurgerProps> = ({ isOpen, toggle }) => (
  <StyledBurger
    isOpen={isOpen}
    onClick={e => {
      e.preventDefault();
      toggle();
    }}
  >
    <VisuallyHidden>Main Menu</VisuallyHidden>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Burger;
